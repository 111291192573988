import React, { useCallback } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import routes from '../../../views'
import './index.scss'

 const Contents = () => {
  const routerPath = useCallback(
    (arr, flag) => {
        let result = []
        arr.map( ele => {
          if(flag) {
            result.push(
              <Route exact key={ele.id} path={ele.path} component={ele.component} />
            )
          }
        })
        return result
    },[])
  return (
    <div className='content-container'>
      <Switch>
        { routerPath(routes,true) }
        <Redirect from='/*' to='/' />
      </Switch>
    </div>
  )
}

export default Contents