import React from 'react';
import { Layout } from 'antd';
import Head from './header';
import Foot from './footer';
import Contents from './content'

const { Header, Footer, Content } = Layout;

export default () => {

  return (
    <>
      <Layout>
        <Header style={{height:'90px'}}>
          <Head />
        </Header>
        <Content>
          <Contents />
        </Content>
        <Footer>
          <Foot />
        </Footer>
      </Layout>
    </>
  )
}