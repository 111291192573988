import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Logo_01, Logo_03 } from '../../../utils/img'
import { useSelector } from "react-redux";
import './index.scss'

const Head = () => {
  const history = useHistory()
  const idx = useSelector(store => store.nva.idx)
  const show = useSelector(store => store.nva.headShow)
  const [indexId, setIndexId] = useState(0)
  const [logoShow, setLogoShow] = useState(true)
  const [path, setPath] = useState()
  const handleNavigation = (eve) => {
    setIndexId(eve.target.tabIndex)
    switch (eve.target.tabIndex) {
      case 0:
        history.push("/")
        setLogoShow(false)
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
        break;
      case 1:
        history.push("/core")
        setLogoShow(false)
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
        break;
      case 2:
        history.push("/solution")
        setLogoShow(false)
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
        break;
      case 3:
        history.push("/join")
        setLogoShow(false)
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
        break;
      case 4:
        history.push("/about")
        setLogoShow(false)
        document.documentElement.scrollTop = 0
        document.body.scrollTop = 0
        break;
      default:
        break;
    }
  }

  window.onload = () => {
    switch (history.location.pathname) {
      case '/':
        setLogoShow(true)
        setIndexId(0)
        break;
      case '/core':
        setLogoShow(false)
        setIndexId(1)
        break;
      case '/solution':
        setLogoShow(false)
        setIndexId(2)
        break;
      case '/join':
        setLogoShow(false)
        setIndexId(3)
        break;
      case '/about':
        setLogoShow(false)
        setIndexId(4)
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    setLogoShow(show)
  }, [show])

  useEffect(() => {
    switch (idx) {
      case 0:
        setLogoShow(true)
        setIndexId(0)
        break;
      case 1:
        setLogoShow(false)
        setIndexId(1)
        break;
      case 2:
        setLogoShow(false)
        setIndexId(2)
        break;
      case 3:
        setLogoShow(false)
        setIndexId(3)
        break;
      case 4:
        setLogoShow(false)
        setIndexId(4)
        break;
      case 5:
        setLogoShow(false)
        setIndexId(5)
        break;
      default:
        break;
    }
  }, [idx])

  useEffect(() => {
    setPath(history.location.pathname)
    switch (path) {
      case '/':
        setLogoShow(true)
        setIndexId(0)
        break;
      case '/core':
        setLogoShow(false)
        setIndexId(1)
        break;
      case '/solution':
        setLogoShow(false)
        setIndexId(2)
        break;
      case '/join':
        setLogoShow(false)
        setIndexId(3)
        break;
      case '/about':
        setLogoShow(false)
        setIndexId(4)
        break;
      default:
        break;
    }
  }, [path, history.location.pathname])

  const handleReplaceHome = () => {
    setLogoShow(true)
    setIndexId(0)
    history.replace('/')
    document.documentElement.scrollTop = 0
    document.body.scrollTop = 0
  }

  return (
    <>
      {
        logoShow ? (
          <div className='header-container-home'>
            <div className='header-wrapper-home'>
              <div className="logo">
                <img src={Logo_01} alt="" onClick={handleReplaceHome} />
              </div>
              <div className="navigation-home" >
                <div
                  className='link'
                  tabIndex='0'
                  onClick={handleNavigation}
                >
                  首页
                </div>
                <div
                  className='link'
                  tabIndex='1'
                  onClick={handleNavigation}
                >
                  核心业务
                </div>
                <div
                  className='link'
                  tabIndex='2'
                  onClick={handleNavigation}
                >
                  产品体系
                </div>
                <div
                  className='link'
                  tabIndex='3'
                  onClick={handleNavigation}
                >
                  加入泛和
                </div>
                <div
                  className='link'
                  tabIndex='4'
                  onClick={handleNavigation}
                >
                  关于泛和
                </div>
              </div>
            </div>
          </div>) : (
          <div className='header-container'>
            <div className='header-wrapper'>
              <div className="logo">
                <img src={Logo_03} alt="" onClick={handleReplaceHome} />
              </div>
              <div className="navigation" >
                <div
                  className='link'
                  tabIndex='0'
                  style={{ color: `${indexId}` === '0' ? "#5551FF" : '#000000' }}
                  onClick={handleNavigation}
                >
                  首页
                </div>
                <div
                  className='link'
                  tabIndex='1'
                  style={{ color: `${indexId}` === '1' ? "#5551FF" : '#000000' }}
                  onClick={handleNavigation}
                >
                  核心业务
                </div>
                <div
                  className='link'
                  tabIndex='2'
                  style={{ color: `${indexId}` === '2' ? "#5551FF" : '#000000' }}
                  onClick={handleNavigation}
                >
                  产品体系
                </div>
                <div
                  className='link'
                  tabIndex='3'
                  style={{ color: `${indexId}` === '3' ? "#5551FF" : '#000000' }}
                  onClick={handleNavigation}
                >
                  加入泛和
                </div>
                <div
                  className='link'
                  tabIndex='4'
                  style={{ color: `${indexId}` === '4' ? "#5551FF" : '#000000' }}
                  onClick={handleNavigation}
                >
                  关于泛和
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default Head