import React from 'react'
import { useHistory } from 'react-router-dom'
import { Logo_01 } from '../../../utils/img'
import './index.scss'
import { useDispatch } from 'react-redux'

const Foot = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const handleClickRoutes = (eve) => {
    switch (eve.target.tabIndex) {
      case 0:
        history.replace("/")
        document.documentElement.scrollTop = 0 
        document.body.scrollTop = 0
        dispatch({type:'UPDATE_STATE_INDEX',payload:eve.target.tabIndex})
        break;
      case 1: 
        history.push("/core")
        dispatch({type:'UPDATE_STATE_INDEX',payload:eve.target.tabIndex})
        document.documentElement.scrollTop = 0 
        document.body.scrollTop = 0
        break;
      case 2:
        history.push("/solution")
        dispatch({type:'UPDATE_STATE_INDEX',payload:eve.target.tabIndex})
        document.documentElement.scrollTop = 0 
        document.body.scrollTop = 0
        break;
      case 3:
        history.push("/join")
        dispatch({type:'UPDATE_STATE_INDEX',payload:eve.target.tabIndex})
        document.documentElement.scrollTop = 0 
        document.body.scrollTop = 0
        break;
      case 4:
        history.push("/about")
        dispatch({type:'UPDATE_STATE_INDEX',payload:eve.target.tabIndex})
        document.documentElement.scrollTop = 0 
        document.body.scrollTop = 0
        break;
      default:
        break;
    }
  }

  return (
    <div className='foot'>
        <div className="foot-container">
          <div className='foot-logo'>
            <img src={ Logo_01 } alt="" />
          </div>
          <div className="foot-list">
            <ul>
              <li tabIndex='0' onClick={handleClickRoutes}>首页</li>
              <li tabIndex='1' onClick={handleClickRoutes}>核心业务</li>
              <li tabIndex='2' onClick={handleClickRoutes}>产品体系</li>
              <li tabIndex='3' onClick={handleClickRoutes}>加入泛和</li>
              <li tabIndex='4' onClick={handleClickRoutes}>关于泛和</li>
            </ul>
          </div>
          <div className='foot-contact'>
            <div>
              <span>联系电话</span>
              <span>191&nbsp;6859&nbsp;7793</span>
            </div>
            <div>
              <span>周一至周五（节假日除外）</span>
              <span>9:00-12:00&nbsp;14:00-18:00</span>
            </div>
          </div>
          <div className='foot-address'>
            <div>
              <span>邮件</span>
              <span>bd@fanhetech.com</span>
              <span>广东省深圳市南山区西丽街道仙元路8号</span>
              <span>传音大厦10层</span>
            </div>
          </div>
          <div className="foot-line"></div>
          <div className='foot-copyright'>
            <span>Copyright 2021.Allrights reserved.Privacy Policy</span>
            <span><a href="https://beian.miit.gov.cn" rel="noopener noreferrer" target='_blank'>粤公网安备440984237483号</a></span>
          </div>
        </div>
    </div>
  )
}
// <span>
// <a href="https://beian.miit.gov.cn" rel="noopener noreferrer" target='_blank'>粤ICP备2021113492号</a>
// </span>
export default Foot