import produce from 'immer'
import {
    UPDATE_STATE_INDEX,
    UPDATE_STATE_SHOW
} from '../type'

const initState = {
    idx:'0',
    headShow:true
}

export default function (state=initState,{type,payload}){
  return produce(state,newState=>{
    switch (type) {
        case UPDATE_STATE_INDEX:
            newState.idx = payload
            break
        case UPDATE_STATE_SHOW:
          newState.headShow = payload
          break
        default:
            newState = initState
    }
  })
}