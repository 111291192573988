import { createStore,combineReducers,applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import nva from './reducers/nva'


export default createStore(
  combineReducers({
    nva
  }),
  applyMiddleware(thunk)
)