import loadable from '@loadable/component'

//路由懒加载
const Home = loadable(()=>import('../views/home'))
const Core = loadable(()=>import('../views/core'))
const Solution = loadable(()=>import('../views/solution'))
const Join = loadable(()=>import('../views/join'))
const About = loadable(()=>import('../views/about'))

export default [
  {
    id: 10,
    title: '首页',
    path: '/',
    component: Home,
  },
  {
    id: 11,
    title: '核心业务',
    path: '/solution',
    component: Solution,
  },
  {
    id: 12,
    title: '产品体系',
    path: '/core',
    component: Core,
  },
  {
    id: 13,
    title: '加入泛和',
    path: '/join',
    component: Join,
  },
  {
    id: 14,
    title: '关于泛和',
    path: '/about',
    component: About,
  }
]